.rct-node-clickable:hover {
  background: #18773322;
  border-radius: 5px;
}

.rct-node-clickable:focus {
  background: none;
}

.react-checkbox-tree label:hover,
.react-checkbox-tree label:focus {
  background: none;
}

.rct-text > label > span {
  display: flex;
}

.rct-text > label > span > svg {
  width: 20px;
  height: 20px;
}