@tailwind base;
@tailwind components;
@tailwind utilities;
@import-normalize;

* {
  font-family: "Poppins", sans-serif;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
img {
  max-height: 100%;
  max-width: 100%;
}

/* INICIO DO CSS DO SITE */

/* ESTILIZAÇÃO ADICIONAL TELA DO LOGIN */

/*IMAGEM DE FUNDO VALGROUP*/
.background-logo {
  background-image: url("./assets/imagens/png/logo-valgroup.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

/*IMAGEM DE FUNDO IDIOMAS*/
#portugues {
  background-image: url("./assets/imagens/png/idioma-portugues.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#ingles {
  background-image: url("./assets/imagens/png/idioma-usa.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#espanhol {
  background-image: url("./assets/imagens/png/idioma-esp.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

/* IMAGEM DO SLIDE */
.slide-1 {
  background-image: url("./assets/imagens/png/slide.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.slide-2 {
  background-image: url("./assets/imagens/png/slide2_Prancheta\ 1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#agil {
  background-image: url("./assets/imagens/png/agil.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#comprometida {
  background-image: url("./assets/imagens/png/comprometida.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#focada-cliente {
  background-image: url("./assets/imagens/png/focada-cliente.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#empreededora {
  background-image: url("./assets/imagens/png/empreendedora.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#background-geral {
  background-image: url("./assets/imagens/png/background-geral.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}
.acoes {
  background-image: url("./assets/imagens/png/acoes.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.mySlides {
  display: none;
}
.dots {
  background-color: #187733;
}
.fade {
  animation-name: fade;
  animation-duration: 2.5s;
}
.dot {
  background-color: #fff;
  transition: background-color 0.6s ease;
}
.bg-modules {
  background-image: url("./assets/imagens/png/bg-modules.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.bg-sweet-loading.bg-sweet-loading.bg-sweet-loading {
  background-color: rgba(0, 0, 0, 0.6) !important;
}
.vg-shadown-custom {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@keyframes fade {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
  }
}
.elem {
  animation: moveToLeft 0.7s ease;
  animation-delay: 250ms;
  animation-fill-mode: forwards;
}

@keyframes moveToLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0px);
  }
}
