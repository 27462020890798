.wifi-symbol {
  display: none;
}
.wifi-symbol [foo],
.wifi-symbol {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 150px;
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.wifi-symbol .wifi-circle {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 21.4285714286px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-color: #1c2a4e;
  border-style: solid;
  border-width: 1em 1em 0 0;
  -webkit-border-radius: 0 100% 0 0;
  border-radius: 0 100% 0 0;
  opacity: 0;
  -o-animation: wifianimation 3s infinite;
  -moz-animation: wifianimation 3s infinite;
  -webkit-animation: wifianimation 3s infinite;
  animation: wifianimation 3s infinite;
}
.wifi-symbol .wifi-circle.first {
  -o-animation-delay: 800ms;
  -moz-animation-delay: 800ms;
  -webkit-animation-delay: 800ms;
  animation-delay: 800ms;
}
.wifi-symbol .wifi-circle.second {
  width: 5em;
  height: 5em;
  -o-animation-delay: 400ms;
  -moz-animation-delay: 400ms;
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms;
}
.wifi-symbol .wifi-circle.third {
  width: 3em;
  height: 3em;
}
.wifi-symbol .wifi-circle.fourth {
  width: 1em;
  height: 1em;
  opacity: 1;
  background-color: #1c2a4e;
  -o-animation: none;
  -moz-animation: none;
  -webkit-animation: none;
  animation: none;
}

@keyframes wifianimation {
  0% {
    opacity: 0.4;
  }
  5% {
    opactiy: 1;
  }
  6% {
    opactiy: 0.1;
  }
  100% {
    opactiy: 0.1;
  }
}
@-o-keyframes wifianimation {
  0% {
    opacity: 0.4;
  }
  5% {
    opactiy: 1;
  }
  6% {
    opactiy: 0.1;
  }
  100% {
    opactiy: 0.1;
  }
}
@-moz-keyframes wifianimation {
  0% {
    opacity: 0.4;
  }
  5% {
    opactiy: 1;
  }
  6% {
    opactiy: 0.1;
  }
  100% {
    opactiy: 0.1;
  }
}
@-webkit-keyframes wifianimation {
  0% {
    opacity: 0.4;
  }
  5% {
    opactiy: 1;
  }
  6% {
    opactiy: 0.1;
  }
  100% {
    opactiy: 0.1;
  }
}
